<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="searchForm.newsTitle"
          placeholder="消息标题"
          clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="getMessage">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="
            dialogVisible = true;
            fileList = [];
          "
          >新增</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-popconfirm title="确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatus"
            >批量删除</el-button
          >
        </el-popconfirm>
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column prop="id" label="序号" width="80" show-overflow-tooltip>
      </el-table-column>
      <!-- <el-table-column prop="msg_id" label="消息编码" show-overflow-tooltip>
      </el-table-column> -->
      <el-table-column
        prop="appName"
        label="APP名称"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="appIcon"
        label="APP图标"
        width="240"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="msgType"
        label="消息类型"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="newsPicUrl"
        label="消息图片地址"
        width="320"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="newsUrl"
        label="消息地址"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="newsTitle"
        label="消息标题"
        width="240"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="newsContent"
        label="消息内容"
        width="240"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="newsTime"
        label="消息推送时间"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="showNotificationFlag"
        label="是否消息通知"
        width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
            size="small"
            v-if="scope.row.showNotificationFlag === '1'"
            type="success"
            >是</el-tag
          >
          <el-tag
            size="small"
            v-else-if="scope.row.showNotificationFlag === '0'"
            type="danger"
            >否</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="playTitleVoiceFlag"
        label="是否语音播报"
        width="80"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
            size="small"
            v-if="scope.row.playTitleVoiceFlag === '1'"
            type="success"
            >是</el-tag
          >
          <el-tag
            size="small"
            v-else-if="scope.row.playTitleVoiceFlag === '0'"
            type="danger"
            >否</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="updated"
        label="更新时间"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="created"
        label="创建时间"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="statu" label="状态" width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success"
            >正常</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" @click="editHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <template>
            <el-popconfirm
              title="确定删除吗？"
              @confirm="delHandle(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="msgHandle(scope.row.id)"
            >消息管理</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
    >
    </el-pagination>

    <!--新增对话框-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="序号" v-if="false" prop="id" label-width="100px">
          <el-input v-model="editForm.id"></el-input>
        </el-form-item>
        <el-form-item label="APP名称" prop="appName" label-width="100px">
          <el-input v-model="editForm.appName"></el-input>
        </el-form-item>
        <el-form-item label="APP图标" prop="appIcon" label-width="100px">
          <el-input v-model="editForm.appIcon"></el-input>
        </el-form-item>
        <el-form-item label="消息类型" prop="msgType" label-width="100px">
          <!-- <el-input v-model="editForm.msgType"></el-input> -->
          <el-select v-model="editForm.msgType" clearable placeholder="请选择">
            <!-- <el-option label="请选择" > </el-option> -->
            <el-option label="news" value="news"> </el-option>
            <el-option label="message" value="message"> </el-option>
            <el-option label="others" value="others"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-upload
                action
                ref="upload"
                :before-remove="beforeRemove"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
                :before-upload="beforeAvatarUpload"
                :http-request="handlerUpload"
              >
                <el-button size="small" icon="el-icon-document" type="primary"
                  >点击上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件, 且不超过1MB
                </div>
              </el-upload></el-col
            >
            <el-col :span="12">
              <img
                :src="
                  editForm.newsPicUrl
                    ? apiUrl.split(':')[0] +
                      ':' +
                      apiUrl.split(':')[1] +
                      '/' +
                      editForm.newsPicUrl
                    : ''
                "
                class="avatar"
            /></el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="消息图片地址"
          prop="newsPicUrl"
          label-width="100px"
        >
          <el-input v-model="editForm.newsPicUrl"></el-input>
        </el-form-item>
        <el-form-item label="消息地址" prop="newsUrl" label-width="100px">
          <el-input v-model="editForm.newsUrl"></el-input>
        </el-form-item>
        <el-form-item label="消息标题" prop="newsTitle" label-width="100px">
          <el-input v-model="editForm.newsTitle"></el-input>
        </el-form-item>
        <el-form-item label="消息内容" prop="newsContent" label-width="100px">
          <el-input v-model="editForm.newsContent"></el-input>
        </el-form-item>
        <!-- <el-form-item label="消息推送时间" prop="newsTime" label-width="120px">
          <el-date-picker
            v-model="editForm.newsTime"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item
          label="是否消息通知"
          prop="showNotificationFlag"
          label-width="120px"
        >
          <el-radio-group v-model="editForm.showNotificationFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="是否语音播报"
          prop="playTitleVoiceFlag"
          label-width="120px"
        >
          <el-radio-group v-model="editForm.playTitleVoiceFlag">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态" prop="statu" label-width="100px">
          <el-radio-group v-model="editForm.statu">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('editForm')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('editForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 消息管理弹出框 -->
    <el-dialog
      title="消息管理"
      class="dialogmsgtitle"
      :visible.sync="MsgDialogVisible"
      width="800px"
      :before-close="handleCloseMsg"
    >
      <p class="Ltitle">当前消息显示</p>
      <el-table
        :data="msgForm.currInfoTable"
        border
        style="width: 100%"
        :show-header="false"
      >
        <!-- <el-table-column class-name="el-table-col" prop="type" width="150"></el-table-column> -->
        <el-table-column prop="name" min-width="50"></el-table-column>
        <el-table-column prop="value" min-width="150"></el-table-column>
      </el-table>
      <el-divider></el-divider>
      <p class="Ltitle">指定消息接收用户角色</p>
      <el-row>
        <el-col :span="20">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkMsgUListAll"
            @change="handleCheckAllChange"
            >ALL</el-checkbox
          ></el-col
        >
        <el-col :span="4">
          <el-button size="small" type="primary" @click="createMsgUserList()"
            >创建人员清单</el-button
          ></el-col
        >
      </el-row>
      <div style="margin: 15px 0"></div>
      <el-checkbox-group v-model="msgUList" @change="handleCheckedRoleChange">
        <el-checkbox
          v-for="i in msgULists"
          :key="i.Value"
          :label="i.Value"
          :value="i.Value"
          >{{ i.Text }}</el-checkbox
        >
      </el-checkbox-group>
      <el-divider></el-divider>
      <p class="Ltitle">接收消息人员列表</p>
      <el-row>
        <el-col :span="20">
          <el-table
            ref="singleTable"
            :data="msgForm.msgUserInfo"
            highlight-current-row
            cell-class-name="singleTable"
            style="
              width: 75%;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 0 6px rgba(0, 0, 0, 0.04);
            "
          >
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <el-table-column property="usercode" label="工号" width="120">
            </el-table-column>
            <el-table-column property="username" label="姓名" width="150">
            </el-table-column>
            <el-table-column
              property="statu"
              label="状态"
              :formatter="showMessageStatus"
            >
            </el-table-column> </el-table
        ></el-col>
        <el-col :span="4">
          <el-button size="small" type="primary" @click="sendMsgUserList()"
            >群发消息</el-button
          ></el-col
        >
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Message",
  data() {
    return {
      searchForm: {},
      delBtlStatus: true,
      total: 0,
      size: 10,
      current: 1,
      dialogVisible: false,
      editForm: {},
      msgForm: {
        msgUserInfo: [],
      },
      tableData: [],
      editFormRules: {
        // url: [{ required: true, message: "请输入URL", trigger: "blur" }],
        appName: [
          { required: true, message: "请输入APP名称", trigger: "blur" },
        ],
        newsTitle: [
          { required: true, message: "请输入消息标题", trigger: "blur" },
        ],
        // newsTime: [
        //   { required: true, message: "请选择消息推送时间", trigger: "change" },
        // ],
        showNotificationFlag: [
          { required: true, message: "请选择是否消息通知", trigger: "change" },
        ],
        playTitleVoiceFlag: [
          { required: true, message: "请选择是否语音播报", trigger: "change" },
        ],
        statu: [{ required: true, message: "请选择状态", trigger: "change" }],
      },

      multipleSelection: [],

      defaultProps: {
        children: "children",
        label: "name",
      },
      MsgDialogVisible: false,
      msgULists: [],
      checkMsgUListAll: false, // 消息推送角色 是否全选
      isIndeterminate: false, //表示 checkbox 的不确定状态
      msgUList: [],
      apiUrl: "",
      fileList: [],
      oldnewsPicUrl: "",
    };
  },
  created() {
    this.getMessage();
    this.apiUrl = this.$axios.defaults.baseURL;
    //TODO
    this.$axios.get("/sys/role/list").then((res) => {
      this.msgULists = [];
      if (res.data.data) {
        res.data.data.records.forEach((item) => {
          this.msgULists.push({ Text: item.name, Value: item.code });
        });
      }
    });
  },
  methods: {
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.delBtlStatus = val.length == 0;
    },

    handleSizeChange(val) {
      this.size = val;
      this.getMessage();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getMessage();
    },
    //关闭弹框 数据清空
    resetForm(formName) {
      if (
        this.oldnewsPicUrl &&
        this.oldnewsPicUrl != this.editForm.newsPicUrl
      ) {
        this.clearFile(this.editForm.newsPicUrl);
        this.oldnewsPicUrl = "";
      }
      if (!this.editForm.id && this.editForm.newsPicUrl) {
        this.clearFile(this.editForm.newsPicUrl);
      }
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },
    //加载消息数据列表
    getMessage() {
      this.$axios
        .get("/sys-message/list", {
          params: {
            name: this.searchForm.newsTitle,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.size = res.data.data.size;
          this.current = res.data.data.current;
          this.total = res.data.data.total;
        });
    },
    //保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(
              "/sys-message/" + (this.editForm.id ? "update" : "save"),
              this.editForm
            )
            .then((res) => {
              this.$message({
                showClose: true,
                message: "恭喜你，操作成功",
                type: "success",
                onClose: () => {
                  this.getMessage();
                },
              });
              if (
                this.oldnewsPicUrl &&
                this.oldnewsPicUrl != this.editForm.newsPicUrl
              ) {
                this.clearFile(this.oldnewsPicUrl);
                this.oldnewsPicUrl = "";
              }
              this.$refs[formName].resetFields();
              this.dialogVisible = false;
              this.editForm = {};
            });
        } else {
          //   console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑弹出
    editHandle(id) {
      this.$axios.get("/sys-message/info/" + id).then((res) => {
        if (res.data.data) {
          this.editForm = res.data.data;
          this.fileList = [];
          this.dialogVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: "查询数据异常",
            type: "error",
            onClose: () => {
              this.getMessage();
            },
          });
        }
      });
    },
    //删除消息
    delHandle(id) {
      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }

      this.$axios.post("/sys-message/delete", ids).then((res) => {
        this.$message({
          showClose: true,
          message: "恭喜你，操作成功",
          type: "success",
          onClose: () => {
            this.getMessage();
          },
        });
      });
    },

    //消息管理弹窗
    msgHandle(id) {
      this.$axios.get("/sys-message/info/" + id).then(async (res) => {
        if (res.data.data) {
          //默认全选关闭 减号关闭
          this.checkMsgUListAll = false;
          this.isIndeterminate = false;
          //填充消息详情信息
          this.msgForm.data = res.data.data;
          this.msgForm.currInfoTable = [];
          this.msgForm.currInfoTable.push({
            type: "app_name",
            name: "APP名称",
            value: this.msgForm.data.appName,
          });
          this.msgForm.currInfoTable.push({
            type: "msg_type",
            name: "消息类型",
            value: this.msgForm.data.msgType,
          });
          this.msgForm.currInfoTable.push({
            type: "news_title",
            name: "消息标题",
            value: this.msgForm.data.newsTitle,
          });
          this.msgForm.currInfoTable.push({
            type: "news_content",
            name: "消息内容",
            value: this.msgForm.data.newsContent,
          });
          this.msgForm.currInfoTable.push({
            type: "news_time",
            name: "消息建立时间",
            value: this.msgForm.data.newsTime,
          });

          //初始化消息接收用户角色列表
          this.msgUList = [];
          this.listMessageSend(res.data.data.id);
          this.MsgDialogVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: "查询数据异常",
            type: "error",
            onClose: () => {
              this.getMessage();
            },
          });
        }
      });
    },
    //消息管理弹框关闭
    handleCloseMsg(done) {
      done();
    },
    //勾选ALL角色
    handleCheckAllChange(val) {
      this.msgUList = [];
      if (val) {
        this.msgULists.forEach((item) => {
          this.msgUList.push(item.Value);
        });
      }
      this.isIndeterminate = false; //全选前面的减号
    },
    //勾选角色多选框时 事件
    handleCheckedRoleChange(value) {
      //修订ALL角色 选项 样式
      let checkedCount = value.length;
      this.checkMsgUListAll = checkedCount === this.msgULists.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.msgULists.length;
    },
    //创建人员名单
    createMsgUserList() {
      if (this.msgUList.length == 0) {
        this.$message({
          showClose: true,
          message: "请至少选择一类角色进行推送",
          type: "error",
        });
        return;
      }
      this.$axios
        .post("/sys-message/create_msg_ulist", {
          msgid: this.msgForm.data.id,
          rolestr: this.msgUList,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "恭喜你，操作成功",
              type: "success",
            });
            this.listMessageSend(this.msgForm.data.id);
          }
        });
    },
    //群发消息
    sendMsgUserList() {
      this.$axios
        .post("/sys-message/send_msg_ulist", {
          msgid: this.msgForm.data.id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              showClose: true,
              message: "推送成功",
              type: "success",
            });
            //刷新
            this.listMessageSend(this.msgForm.data.id);
          } else {
            this.$message({
              showClose: true,
              message: "推送失败",
              type: "error",
            });
          }
        });
    },
    //获取 接收消息人员列表
    listMessageSend(id) {
      this.$axios.get("/sysmsgsend/list?msgid=" + id).then((ret) => {
        this.msgForm.msgUserInfo = ret.data.data.records;
      });
    },
    //格式化消息状态
    showMessageStatus(row, column, cellValue, index) {
      switch (cellValue) {
        case 0:
          return "0 初始化";
        case 1:
          return "1 已发送";
        case 2:
          return "2 已接收";
        default:
          return cellValue;
      }
    },
    //图片处理 start
    handleExceed(files) {
      this.$message.warning(`每次只能选择1个文件`);
    },
    beforeRemove(file) {
      console.log("file", file);
      if (
        (file.raw.type === "image/jpeg" || file.raw.type === "image/png") &&
        file.size / 1024 / 1024 < 1
      ) {
        return new Promise((reslove, reject) => {
          this.$confirm(`确定移除 ${file.name}?`).then(() => {
            this.$axios
              .post(
                "/fileoss/delOssFile?filename=" +
                  [
                    this.editForm.newsPicUrl.split("/")[
                      this.editForm.newsPicUrl.split("/").length - 1
                    ],
                  ],
                {}
              )
              .then((res) => {
                if (res.data.code == 200) {
                  this.editForm.newsPicUrl = "";
                  this.$forceUpdate();
                  reslove();
                } else {
                  reject();
                }
              })
              .catch((err) => {
                reject();
              });
          });
        });
      }
    },
    handlerUpload(param) {
      let fileObject = param.file;
      let formData = new FormData();
      formData.append("file", fileObject);
      this.$axios.post("/fileoss/uploadOssFile", formData).then((res) => {
        if (res.data.data.startsWith("OK")) {
          console.log(this.editForm.newsPicUrl);
          if (this.editForm.newsPicUrl) {
            this.oldnewsPicUrl = this.editForm.newsPicUrl;
          }
          this.editForm.newsPicUrl = res.data.data.split(":")[1];
          //TODO 文件服务器地址 获取方式未确定

          this.$forceUpdate();
        }
      });
    },
    //上传图片配置
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isType) {
        this.$message.error("上传图片只能是 JPG 或者 PNG 格式！");
      }
      if (!isLt1M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }

      return isType && isLt1M;
    },
    clearFile(urlimg) {
      if (urlimg) {
        this.$axios
          .post(
            "/fileoss/delOssFile?filename=" +
              urlimg.split("/")[urlimg.split("/").length - 1]
          )
          .then((res) => {});
      }
    },
    //图片处理 end
  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
.el-table /deep/ thead .el-table__cell {
  text-align: center;
}
.el-table /deep/ .singleTable {
  text-align: center;
}
.el-dialog__title /deep/.dialogmsgtitle {
  color: blueviolet;
}

.Ltitle {
  color: #2698ab;
}
.el-pagination {
  float: right;
  margin-top: 22px;
}
.avatar {
  width: 208px;
  height: 128px;
}
.el-upload {
  text-align: left;
  width: 100%;
}
</style>
